import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Heading.module.css";

Heading.propTypes = {
  tag: PropTypes.string,
  // xxxl doesn't exist in the design system, but was used on mtrx and is kept for backwards compatibility
  variant: PropTypes.oneOf([
    "xxxl",
    "title1",
    "title2",
    "title3",
    "title4",
    "title5",
  ]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "disabled",
    "black",
    "white",
    "inherit",
  ]),
  center: PropTypes.bool,
  gutter: PropTypes.oneOf(["sm", "md"]),
  className: PropTypes.string,
  upperCase: PropTypes.bool,
  children: PropTypes.node.isRequired,
  fontFamily: PropTypes.oneOf(["primary", "alternate"]),
};

Heading.defaultProps = {
  tag: "h2",
  variant: "title1",
  center: false,
  color: "inherit",
  upperCase: false,
  className: "",
  fontFamily: "alternate",
  gutter: undefined,
};

export default function Heading({
  tag: Tag,
  variant: rawVariant,
  color,
  center,
  children,
  className,
  upperCase,
  fontFamily,
  gutter,
}) {
  const oldVariantMap = {
    xs: "title5",
    sm: "title4",
    md: "title3",
    l: "title2",
    xl: "title1",
    xxl: "title1",
  };

  const variant = oldVariantMap[rawVariant] || rawVariant;

  // XXX: REMOVE THIS:
  const oldColor = (() => {
    switch (color) {
      case "red":
      case "pink":
      case "wine":
        return "#FF00FF";
      default:
        return undefined;
    }
  })();

  return (
    <Tag
      style={{ color: oldColor && `${oldColor} !important` }}
      className={classnames(
        {
          [styles.xxxl]: variant === "xxxl",
          [styles.title1]: variant === "title1",
          [styles.title2]: variant === "title2",
          [styles.title3]: variant === "title3",
          [styles.title4]: variant === "title4",
          [styles.title5]: variant === "title5",
          [styles.white]: color === "white",
          [styles.black]: color === "black",
          [styles.inherit]: color === "inherit",
          [styles.center]: center,
          [styles.upperCase]: upperCase,
          [styles.fontAlternate]: fontFamily === "alternate",
          [styles.fontPrimary]: fontFamily === "primary",
          [styles.gutterMd]: gutter === "md",
          [styles.gutterSm]: gutter === "sm",
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
          [styles.textPrimary]: color === "textPrimary",
          [styles.textSecondary]: color === "textSecondary",
        },
        className
      )}
    >
      {children}
    </Tag>
  );
}
