import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

import Container from "#components/Container";
import useComponentDidMount from "#hooks/useComponentDidMount";
import ChevronLeftIcon from "#icons/chevron-left.svg";
import FilledCheckIcon from "#icons/mtrx-check.svg";

import { BACK, STEPS_MOBILE } from "./nodes";
import styles from "./Steps.module.css";

export const STEPS_ID = "steps";

StepsComponent.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  stepsData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      step: PropTypes.number.isRequired,
    })
  ).isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabled: PropTypes.bool,
  hideBack: PropTypes.bool,
};

StepsComponent.defaultProps = {
  disabled: false,
  hideBack: false,
};

function StepsComponent({
  step: currentStep,
  setStep,
  disabled,
  back,
  stepsData,
  steps,
  hideBack,
}) {
  const actualCurrentStep = currentStep - 1;
  const filteredSteps = stepsData.filter(({ step }) => steps.includes(step));

  return (
    <div id={STEPS_ID} className={styles.root}>
      <div className={styles.containerDesktop}>
        <Container>
          <ol
            className={classnames(styles.desktopSteps, {
              [styles.disabled]: disabled,
            })}
          >
            {filteredSteps.map((step, index) => {
              const num = index + 1;
              const actualStep = step.step;

              const content = (
                <span className={styles.stepInner}>
                  <div>
                    <span
                      className={classnames(styles.circle, {
                        [styles.activeStep]: actualStep === currentStep,
                      })}
                    >
                      {actualStep < currentStep ? (
                        <FilledCheckIcon />
                      ) : (
                        <>
                          <span>{num}</span>
                        </>
                      )}
                    </span>
                    <span
                      className={classnames({
                        [styles.stepTextCurrent]: num <= currentStep,
                      })}
                    >
                      {step.text}
                    </span>
                  </div>
                </span>
              );

              return (
                <li
                  key={num}
                  className={classnames(styles.step, {
                    [styles.current]: actualStep <= currentStep,
                  })}
                >
                  {actualStep < currentStep ? (
                    !disabled ? (
                      <button
                        onClick={() => {
                          setStep(actualStep);
                        }}
                        type="button"
                      >
                        {content}
                      </button>
                    ) : (
                      <span>{content}</span>
                    )
                  ) : (
                    content
                  )}
                </li>
              );
            })}
          </ol>
        </Container>
      </div>

      <div className={styles.mobileSteps}>
        {!hideBack && (
          <div>
            <button type="button" onClick={back}>
              <a>
                <span className={styles.chevronCircle}>
                  <ChevronLeftIcon className={styles.chevron} />
                </span>
                <span className={styles.backButtonText}>{BACK}</span>
              </a>
            </button>
          </div>
        )}

        <div>
          {React.cloneElement(STEPS_MOBILE, {
            step: steps.indexOf(currentStep) + 1,
            total: steps.length,
          })}{" "}
          {stepsData[actualCurrentStep] && stepsData[actualCurrentStep].text}
        </div>
      </div>
    </div>
  );
}

export default function Steps({ navSelector, ...props }) {
  const mounted = useComponentDidMount();

  const targetElement =
    typeof document === "undefined"
      ? undefined
      : document.querySelector(navSelector);

  return mounted && targetElement
    ? ReactDOM.createPortal(<StepsComponent {...props} />, targetElement)
    : null;
}
