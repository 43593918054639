import React from "react";

export default function useComponentDidMount() {
  const [mounted, setMounted] = React.useState();

  React.useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
}
