import PropTypes from "prop-types";
import React from "react";

import styles from "./Spinner.module.css";

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 40,
};

export default function Spinner({ size }) {
  const childSize = size * 0.8;
  const childProps = {
    style: {
      width: childSize,
      height: childSize,
    },
  };

  return (
    <div className={styles.root} style={{ width: size, height: size }}>
      <div {...childProps} />
      <div {...childProps} />
      <div {...childProps} />
      <div {...childProps} />
    </div>
  );
}
