import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Section.module.css";

Section.propTypes = {
  animated: PropTypes.bool,
  variant: PropTypes.oneOf([
    "pink",
    "red",
    "regular",
    "white",
    "grey",
    "dark",
    "alert",
  ]),
  padding: PropTypes.oneOf(["none", "tight", "regular", "extra"]),
  position: PropTypes.oneOf(["top", "middle"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  variant: "regular",
  padding: "none",
  position: "middle",
  className: undefined,
  animated: false,
};

export default function Section({
  variant,
  animated,
  padding,
  position,
  children,
  className,
  style,
}) {
  return (
    <div
      style={style}
      className={classnames({
        [styles.animated]: animated,
        [styles.white]: variant === "white",
        [styles.grey]: variant === "grey",
        [styles.dark]: variant === "dark",
        [styles.pink]: variant === "pink",
        [styles.red]: variant === "red",
        [styles.alert]: variant === "alert",
        [styles.top]: position === "top",
      })}
    >
      <div
        className={classnames(
          {
            [styles.padding]: padding === "regular",
            [styles.extra]: padding === "extra",
            [styles.tight]: padding === "tight",
            [styles.none]: padding === "none",
          },
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
