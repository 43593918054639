import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import Text from "#components/Text";
import ChevronIcon from "#icons/chevron.svg";

import styles from "./Tabs.module.css";

export const Tab = ({ children, className, active, ...props }) => {
  return (
    <button
      type="button"
      className={classnames(styles.tab, className)}
      aria-pressed={active}
      {...props}
    >
      <Text variant="paragraph16" weight="semibold">
        {children}
      </Text>
    </button>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
};
Tab.defaultProps = {
  active: false,
  className: undefined,
};

const Accordion = ({
  onClick,
  contentClass,
  className,
  title,
  content,
  active,
  disallowAccordion,
}) => {
  return (
    <div className={classnames(styles.accordion, className)}>
      <button
        type="button"
        onClick={onClick}
        aria-pressed={active}
        className={classnames(styles.accordionHeader, {
          [styles.disallowAccordion]: disallowAccordion,
        })}
      >
        {title}
        <ChevronIcon />
      </button>
      <div
        className={classnames(
          styles.accordionContent,
          {
            [styles.visible]: active,
            [styles.alwaysVisibleOnPhone]: disallowAccordion,
          },
          contentClass
        )}
      >
        {content}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disallowAccordion: PropTypes.bool,
  contentClass: PropTypes.string,
  className: PropTypes.string,
};

Accordion.defaultProps = {
  active: false,
  disallowAccordion: false,
  contentClass: undefined,
  className: undefined,
};

const Tabs = ({
  tabs,
  tabIndex,
  tabClass,
  disallowAccordion,
  disableAccordion,
  className,
  onChange,
  ...props
}) => {
  const [activeTab, setTab] = React.useState(tabIndex);

  useEffect(() => {
    setTab(tabIndex);
  }, [tabIndex]);

  const handleClick = (index) => {
    setTab(index);
    if (onChange) onChange(index);
  };

  const desktopTabs = tabs.map(
    ({ title, tabClass: tc2, startIcon, endIcon }, index) => (
      <Tab
        key={index}
        active={activeTab === index}
        className={classnames(tabClass, tc2)}
        onClick={() => handleClick(index)}
      >
        {startIcon}
        {title}
        {endIcon}
      </Tab>
    )
  );

  const accordion = tabs.map((tab, i) => {
    const isActive = activeTab === i;
    return (
      <Accordion
        disallowAccordion={disallowAccordion}
        onClick={() => setTab(isActive ? undefined : i)}
        key={i}
        active={isActive}
        {...props}
        {...tab}
      />
    );
  });

  return (
    <div>
      <div className={classnames(styles.tabContainer, className)}>
        {desktopTabs}
      </div>
      {!disableAccordion && accordion}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    })
  ).isRequired,
  disallowAccordion: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  tabClass: PropTypes.string,
};

Tabs.defaultProps = {
  tabIndex: 0,
  disallowAccordion: false,
  className: undefined,
  tabClass: undefined,
};

Tab.displayName = "Tabs";

export default Tabs;
