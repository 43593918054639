import { Node } from "djedi-react";
import React from "react";

export const STEPS_MOBILE = (
  <Node uri="Steps/mobile" step="-1" total="-1">
    Steg [step] av [total].
  </Node>
);

export const BACK = <Node uri="Steps/back">Tillbaka</Node>;
